<template>
    <div>
        <toast-factory />
        <notifications group="foo" position="center" />
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'App',
    created() {
        this.$GlobalEventEmitter.$on('navigate', (route) => {
            // setup the global event listener to navigate to any route from places where router instance does not exist, such as vuex actions
            // this global event listener will also remove the import/no-cycle eslint error caused by cyclic dependency between router and vuex
            this.$router.push(route).catch(() => {});
        });
    },
};
</script>
