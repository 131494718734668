import _ from 'lodash';

import { makeToast } from './GlobalEventEmitter';

export default function showAPIErrorToast(err) {
    const errMessages = err.response?.data?.message;
    if (errMessages && errMessages?.length > 0) {
        if (Array.isArray(errMessages)) {
            errMessages.forEach((msg) => {
                const transformedFieldName = _.startCase(msg.substring(0, msg.indexOf(' ')).split('_')?.join(' '));
                const transformedMsg = `${transformedFieldName} ${msg.substring(msg.indexOf(' ') + 1, msg.length)}`;

                makeToast({
                    variant: 'danger',
                    msg: transformedMsg,
                });
            });
        } else {
            makeToast({
                variant: 'danger',
                msg: errMessages,
            });
        }
    } else {
        makeToast({
            variant: 'danger',
            msg: err.message,
        });
    }
}
