import debug from 'debug';

// Define different namespaces for different log types
const log = debug('app:log');
const error = debug('app:error');

// Enable all log types
debug.enable('app:*');

// Export the log functions as default
/**
 * @typedef {Object} Logger
 * @property {function(...*): void} log
 * @property {function(...*): void} error
 */

/**
 * @type {Logger}
 */
export default {
    log: (...args) => {
        log(...args);
    },
    error: (...args) => {
        error(...args);
    },
};
